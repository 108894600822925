import React from "react";
import { Link } from "gatsby";
import { navigate } from "gatsby";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
export default {
  React,
  Link,
  navigate,
  getImage,
  GatsbyImage
};