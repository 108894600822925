import { Table, Card, Container, Row, Col } from "react-bootstrap";
import { navigate } from "gatsby";
import Trainingresources from "../../../../src/components/trainingresources";
import * as React from 'react';
export default {
  Table,
  Card,
  Container,
  Row,
  Col,
  navigate,
  Trainingresources,
  React
};