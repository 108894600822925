import { Table, Card, Container, Row, Col } from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs';
import TabPane from 'react-bootstrap/TabPane';
import Progress from "../../../../src/components/progress-bar";
import * as React from 'react';
export default {
  Table,
  Card,
  Container,
  Row,
  Col,
  Tabs,
  TabPane,
  Progress,
  React
};