import { Table, Card, Container, Row, Col } from "react-bootstrap";
import { navigate, Link } from "gatsby";
import * as React from 'react';
export default {
  Table,
  Card,
  Container,
  Row,
  Col,
  navigate,
  Link,
  React
};