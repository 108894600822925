import { Link } from 'gatsby';
import Circles from "../../../../src/components/circles";
import Numbers from "../../../../src/components/numbers";
import BoxGrid from "../../../../src/components/box-grid";
import Partners from "../../../../src/components/partners";
import { Container, Row, Col, Card } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from "gatsby";
import * as React from 'react';
export default {
  Link,
  Circles,
  Numbers,
  BoxGrid,
  Partners,
  Container,
  Row,
  Col,
  Card,
  StaticImage,
  navigate,
  React
};